import React from 'react'
import Header from '../header/header'
import styled from 'styled-components'
import FaqQuestions from './faqQuestions'
import media from '../media'

const StyledFaqTopContainer = styled.div`
${media.mobileS`
margin-top:100px;
`}
${media.laptop`
margin-top:0;
`}

background-color:#212121;

`
const StyledFaqTitle = styled.h1`
${media.mobileS`
font-size:1.5rem;
text-align:center;
`}
${media.tablet`
font-size:2rem;
`}
color:white;

font-family:sans-serif;
margin:auto;
`
const StyledTitleContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
padding:10% 0;

${media.laptop`
padding:5% 0;
`}
`

const faq = () => {
    return (
        <div>
        <StyledFaqTopContainer>
            <Header/>
            <StyledTitleContainer>
                <StyledFaqTitle>Frequently Asked Questions</StyledFaqTitle>
            </StyledTitleContainer>
        </StyledFaqTopContainer>
        <FaqQuestions/>
     </div>
    )
}

export default faq
