import React from "react"
import Layout from "../components/layout"
import Faq from '../components/faq/faq'
import MobileHeader from '../components/header/headerMobile'

const faq = () => {
    return(
    <Layout>
        <MobileHeader/>
        <Faq/>
        </Layout>
    
    )}

export default faq
