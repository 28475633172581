import React from 'react'
import styled from 'styled-components'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import media from '../media'


const styles = theme => ({
    // Look at here: applied specific styles to resizing and background
    expansion: {
      width: "70vw",
      backgroundColor:"dimgrey",
      borderRadius:"10px",
      // borderColor:"orange",
      margin:".5% 0",
    },
    answer:{
        color:"white"
    },
    question:{
      color:"orange",
        fontWeight:"600"
    }
  });

const StyledFaqContainer=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background-color:#212121;
padding-bottom:5%;
`
const StyledFaqSectionTitle = styled.h2`
color:orange;
font-size:1.5rem;
padding: 2% 0;
`
const StyledLinks = styled.a`
text-decoration:underline;
color:white;
&:hover{
  color:orange;
}
`  
const StyledExpansionPanel = styled(ExpansionPanel)`
&:hover{
  background-color:slategray;
}
`
const StyledExpandIcon = styled(ExpandMoreIcon)`
color:white;
`
const StyledColumn = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
`
const StyledQuestion = styled.p`
color:orange;
font-family:sans-serif;
font-weight:600;
${media.mobileS`font-size:1rem;`}
${media.tablet`font-size:1.1rem;`}
${media.laptopL`font-size:1.2rem;`}


`
const StyledAnswer = styled.p`

color:white;
font-family:sans-serif;
${media.mobileS` font-size:.8rem; 
text-align:natural;`}

${media.tablet`font-size:1rem;`}
${media.laptopL`font-size:1.1rem;`}


`
const faqQuestions = (props) => {
    const {classes} = props
    return (
        <StyledFaqContainer>
            <StyledFaqSectionTitle>About Temporal</StyledFaqSectionTitle>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Who else offers a service similar to Temporal?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
         <StyledColumn>
           <StyledAnswer>We provide the most feature-complete and competitively priced services. There are, however, other third-party IPFS service providers.</StyledAnswer>
        <br></br>
           <StyledAnswer>Our API primarily runs out of IPFS dedicated data center, as opposed to traditional cloud providers like AWS and Digital Ocean.</StyledAnswer>
           <br></br>
           <StyledAnswer>Our resilient infrastructure running Temporal makes everything effortless from pinning files to the IPFS network to deploying its API.</StyledAnswer>
         </StyledColumn>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What are some of Temporal's main features?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledColumn> 
            <StyledAnswer>-&nbsp;IPFS uploads &amp; pinning</StyledAnswer>
            <StyledAnswer>-&nbsp;IPFS search engine </StyledAnswer>
            <StyledAnswer>-&nbsp;IPNS publishing</StyledAnswer>
            <StyledAnswer>-&nbsp;IPFS key management </StyledAnswer>
            <StyledAnswer>-&nbsp;PubSub messaging</StyledAnswer>

          </StyledColumn>



        </ExpansionPanelDetails>
      </StyledExpansionPanel>
      
      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What are Temporal's most utilized features by clients?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledColumn> 
            <StyledAnswer>-&nbsp;IPFS private network management</StyledAnswer>
            <div style={{display:"flex"}}><span style={{color:"white"}}>-&nbsp;</span><StyledLinks href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/ipfs.html#ipfs-http-api-proxy"><StyledAnswer>IPFS HTTP API reverse proxy </StyledAnswer></StyledLinks></div>
            <StyledAnswer>-&nbsp;Enterprise IPFS nodes and folder uploads. </StyledAnswer>
          </StyledColumn>



        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Does Temporal have an IPFS gateway?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledColumn> 
            <StyledAnswer>Temporal currently maintains two public-facing IPFS gateways: Clearnet and I2P for added privacy.</StyledAnswer>
          </StyledColumn>



        </ExpansionPanelDetails>
      </StyledExpansionPanel>
      

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Does Temporal have any software development kits?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{display:"flex",flexDirection:"column"}}>
          <StyledLinks href="https://github.com/postables/temporal-js"><StyledAnswer>JavaScript SDK</StyledAnswer></StyledLinks>
          <StyledLinks href="https://github.com/RTradeLtd/thc/"><StyledAnswer>Golang SDK</StyledAnswer></StyledLinks>
          </div>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>What's unique about Temporal compared to your competitors?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          It has some of the most resilient IPFS infrastructures. It is a Turn-Key Solution that can easily integrate into any current enterprise network, any application or used to build a brand new one. Temporal will give your company a competitive advantage because you’ll be able to move fast and more cost-effectively, PLUS, you will be able to do things with data that have never been done before. Temporal opens new doors to new and robust business solutions.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>Which Industries would benefit the most from Temporal?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <StyledColumn>
              <StyledAnswer>Any industry that stores and manages data on a large or small enterprise network will benefit by saving considerable time and money. The following sectors especially will find Temporal to be a valuable tool to save their companies time and money.</StyledAnswer>
              <br></br>
              <StyledAnswer>* FinTech & Accounting</StyledAnswer>
              <StyledAnswer>* Bio & Pharmaceuticals</StyledAnswer>
              <StyledAnswer>* Manufacturing & Distribution</StyledAnswer>
              <br></br>
              <StyledAnswer> … and many more - just ASK!</StyledAnswer>
            </StyledColumn>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>What exactly is Big Data?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Webopedia describes it like this: "Big Data is a phrase used to mean a massive volume of both structured and unstructured data that is so large it is difficult to process using traditional database and software techniques. In most enterprise scenarios, the volume of data is too big, or it moves too fast, or it exceeds current processing capacity." We couldn't have said it better.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>What does Big Data do?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Big data is the epicentre of customer and client integration. A company can improve supply chain efficiency by collecting and evaluating big data to track how, when, and where products move. You can also identify inefficiencies and where costs and time can be optimized. RFID chips, wireless sensors, automated logs and transactional data can track information from raw material to the customer's home. Using systems like Temporal, you can collate any level of structured and unstructured data from all types of contact points between B2B and B2C, and do it from a 360-degree mountaintop view of your customers. Data can generate through social media, IoT, mobile devices, data logs, and much more. Big Data can help you predict, identify and mitigate fraud. You can monitor transactions in real-time, which allows you to dynamically detect uncharacteristic patterns and meta-patterns that can prevent or lessen fraudulent behaviour. Big data provides the fuel for predictive and prescriptive analytics and enables a company to compare historical and transactional data to identify and mitigate fraud.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>How do I manage Big Data?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <StyledColumn>
          <StyledAnswer>
         Install Temporal
          </StyledAnswer>
          <StyledAnswer>
          Identify what you want Big Data to do
          </StyledAnswer>
          <StyledAnswer>
          Collect data from a variety of sources
          </StyledAnswer>
          <StyledAnswer>
          Know which data to capture
          </StyledAnswer>
          <StyledAnswer>
          Store your data cost effectively          
          </StyledAnswer>
          <StyledAnswer>
          Secure your data from physical damage
          </StyledAnswer>
          <StyledAnswer>
          Protect the data from hackers
          </StyledAnswer>
          <StyledAnswer>
           Manage dynamic audit conventions
          </StyledAnswer>
          <StyledAnswer>
           Facilitate digital communication between big data
          </StyledAnswer>
          </StyledColumn>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>Can I manage Big Data on a small enterprise?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes, Temporal is designed to provide a small company with all the features of a large enterprise at a price that scales accordingly.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>Do I have to know anything about IPFS, to use Temporal?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes, a little bit, but a lot less than you might think because Temporal does all the heavy lifting.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>Can Temporal be installed for my small enterprise at my location?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes, relatively quickly, or you can attain FREE storage through Temporal, and purchase more as you need it.
           </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>How can my small enterprise be affected? </StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          In most cases, they are considerably faster to adapt and implement emerging technologies than large enterprises. Keeping current clients happy and quickly gaining new ones. 
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledQuestion>Can my small enterprise stay competitive?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes. They are leaner and relatively easy to manage through solutions like Temporal that automates many of the features.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledFaqSectionTitle>About IPFS</StyledFaqSectionTitle>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What is IPFS?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          IPFS is a robust peer-to-peer data distribution network that shares some characteristics with technologies like BitTorrent and Git. Data is content-addressed, which enables immutable and verifiable data structures. It allows increased data transfer speeds, better data security, and allows you to create powerful applications leveraging the underlying technologies of IPFS. According to IPFS.io, IPFS is the Distributed Web. IPFS delivers high throughput and low latency data distribution.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Is Temporal dependant on IPFS technology?

</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Temporal primarily relies on IPFS as a decentralized storage provider. It will be extended to include new distributed storage networks as their merit and usefulness become established.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>



      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Why is IPFS better then HTTP? </StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
         <StyledColumn>
            <StyledAnswer >HTTP is outdated and no longer cost-efficient and cannot effectively manage big data.</StyledAnswer >
            <StyledAnswer >HTTP downloads a file from a single server, one at a time.</StyledAnswer >
            <StyledAnswer  >IPFS retrieves data from multiple computers simultaneously, which saves tremendously in bandwidth costs.</StyledAnswer >
            <StyledAnswer  >IPFS can distribute high volumes of data with optimized efficiency and with zero duplication, which considerably reduces storage costs.</StyledAnswer >
            </StyledColumn>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>



      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>IPFS Benchmark Testing?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
         
          We currently do not have any documented benchmarks of IPFS performance, but if you register for our upcoming newsletter, we will let you know when we have numbers.

          </StyledAnswer>

        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>How does IPFS benefit an enterprise?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          IPFS reinvents cloud computing technology by taking data networks beyond “decentralized,” to a more advanced level of DISTRIBUTED, which represents a significant shift in what we can now do with data. The benefits of IPFS for an enterprise are in low switching costs, interoperability across storage providers, and capacity to mix and match features of cloud storage. This elasticity creates efficiencies and business opportunities that have been previously unfeasible because of the high costs of today’s third-party cloud offerings.  </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Does IPFS save time and money?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes. IPFS provides deduplication, clustered persistence, and high performance. You can manage large amounts of data with IPFS, and place immutable, permanent IPFS links into a blockchain transaction, which timestamps and secures your content without having to put the data itself on the chain.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Is Temporal affiliated with: IPFS, FileCoin, Protocol Labs and others?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          No. However, we have many contributions to upstream IPFS and LibP2P official code repositories helping build the next generation of the internet. 
        </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What is the Temporal Interplanetary Playground?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          The Playground is an easy-to-use test-pad that allows you to see and use many of Temporal’s features in a safe space. Using Temporals API, we have created the world's first full features IPFS web interface. 
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Is the Temporal Interplanetary Playground FREE?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes!
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What is Open source? Is it free? </StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes and No. Open source refers to source code that is made available for use or modification by developers. It is usually developed as a public collaboration and made freely available. The code is free, but there is a cost associated with hiring a developer if you want to modify the code.          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What is Web 3.0, and why is it better?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
         <StyledColumn>
         <br></br>
          <StyledAnswer><span style={{fontWeight:"600"}}>Web 1.0 </span> was a Read-Only Web - websites were static like brochures.</StyledAnswer>
            <StyledAnswer ><span style={{fontWeight:"600"}}>Web 2.0 </span>was the read-writing participating web interacting or contributing content.</StyledAnswer>
           <br></br>
            <StyledAnswer ><span style={{fontWeight:"600"}}>Web 3.0 </span>is a read-write-execute Web where dynamic data is the main feature. Web 3.0 is an artificially intelligent, semantic web where both humans and computers can understand, learn and use the same information. It will be more connected, open, and smart, with semantic Web technologies, distributed databases, natural language processing, machine learning, machine reasoning, and autonomous agents.
</StyledAnswer>
            </StyledColumn>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Who is RTrade?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          They are the technologies company responsible for the development of the Temporal platform. Based out of Vancouver, British Columbia, Canada, the RTrade team shares a common understanding of the fundamentals of P2P technology and the potential this technology has to improve our lives. RTrades team is comprised of specialists in finance, technology, business, and security. Diversity and experience are our greatest strengths, and we are devoted to helping shape the future of technology so that all of humanity can grow, connect, learn and prosper together.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Will RTrade sell tokens?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Our token, RTC, can be purchased via a smart contract that has capabilities similar to Proof-Of-Stake. Staking generates new RTC, which is an accepted payment method for our services. RTC was launched primarily to serve as an exclusive reward system that also lets you pay for our services.
         </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Does RTrade plan to list on an exchange?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          We are currently undecided, but might list on an exchange in the future.</StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>


      <StyledFaqSectionTitle>About Blockchain</StyledFaqSectionTitle>
      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>What is Blockchain?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          A blockchain is a distributed private or public digital ledger that is used to record transactions across many computers so that any involved record cannot be altered retroactively, without the alteration of all subsequent blocks.
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Does Temporal need blockchain technology ?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          No. Temporal works just as efficiently on or off blockchain.</StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

      <StyledExpansionPanel className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<StyledExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledQuestion>Can I build DApps and scale Blockchains with Temporal?</StyledQuestion>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StyledAnswer>
          Yes, you can build, deploy and even manage them. Scaling any blockchain can be done in a snap!
          </StyledAnswer>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>

{/* end of Questions */}
        </StyledFaqContainer>
    )
}

export default withStyles(styles)(faqQuestions)
